var _defineProperty = require("/home/jenkins/oskar/work/ArangoDB/js/apps/system/_admin/aardvark/APP/react/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

/* jshint strict: false */

/* global _, Backbone, frontendConfig, window, arangoHelper, sessionStorage, $ */
window.UserConfig = Backbone.Model.extend({
  defaults: {
    graphs: '',
    queries: []
  },
  ldapEnabled: false,
  initialize: function initialize(options) {
    this.ldapEnabled = options.ldapEnabled;
    this.getUser();
  },
  model: window.UserConfigModel,
  fetch: function fetch(options) {
    options = _.extend({
      parse: true
    }, options);
    var model = this;
    var success = options.success;

    if (this.ldapEnabled) {
      this.getLocalConfig();

      options.success = function (resp) {
        // if success function available, call it
        if (success) {
          success.call(options.context, model, resp, options);
        }
      }();
    } else {
      // if ldap is not enabled -> call Backbone's fetch method
      return Backbone.Collection.prototype.fetch.call(this, options);
    }
  },
  getConfigPath: function getConfigPath() {
    return frontendConfig.db + '-' + this.username + '-config';
  },
  getLocalConfig: function getLocalConfig() {
    var item = sessionStorage.getItem(this.getConfigPath());

    try {
      item = JSON.parse(item);
    } catch (ignore) {}

    if (item) {
      this.set(item);
    } else {
      this.set({});
    }

    return this.toJSON();
  },
  saveLocalConfig: function saveLocalConfig() {
    sessionStorage.setItem(this.getConfigPath(), JSON.stringify(this.toJSON()));
  },
  getLocalItem: function getLocalItem(keyName, callback) {
    if (callback) {
      callback(this.get(keyName));
    }

    return this.get(keyName);
  },
  setLocalItem: function setLocalItem(keyName, keyValue, callback) {
    try {
      this.set(keyName, keyValue);
      this.saveLocalConfig();

      if (callback) {
        callback();
      }
    } catch (ignore) {}
  },
  getUser: function getUser() {
    if (window.App.currentUser) {
      this.username = window.App.currentUser;
    } else {
      this.username = 'root';
    }
  },
  parse: function parse(response) {
    return response.result;
  },
  url: function url() {
    return arangoHelper.databaseUrl('/_api/user/' + encodeURIComponent(this.username) + '/config');
  },
  setItem: function setItem(keyName, keyValue, callback) {
    var _this = this;

    // keyName: Name where the objects will be stored.
    // keyValue: Object the user wants to store.
    // callback: Function which will be executed after successful exit.
    this.fetch({
      success: function success(data) {
        storeToDB(data.toJSON());
      },
      error: function error(data) {
        if (data.responseJSON && data.responseJSON.errorMessage && data.responseJSON.errorNum) {
          arangoHelper.arangoError('Graph Config', "".concat(data.responseJSON.errorMessage, "[").concat(data.responseJSON.errorNum, "]"));
        } else {
          arangoHelper.arangoError('Graph Config', 'Could not fetch graph configuration. Cannot save changes!');
        }
      }
    });

    var mergeDatabaseDataWithLocalChanges = function mergeDatabaseDataWithLocalChanges(dataFromDB, keyName, newObject) {
      // Currently a local merge is required as the "_api/user/<user>/config"
      // API does not do a merge internally. It does a replacement instead.
      var isObject = function isObject(varToCheck) {
        if (typeof varToCheck === 'object' && !Array.isArray(varToCheck) && varToCheck !== null) {
          return true;
        } else {
          return false;
        }
      };

      if (!dataFromDB) {
        return newObject;
      } // Format notice
      // dataFromDB<object>: dataFromDB.result.keyName


      if (dataFromDB.result && dataFromDB.result[keyName]) {
        var existingEntry = dataFromDB.result[keyName]; // We've found available data we need to merge

        if (isObject(existingEntry) && isObject(newObject)) {
          return _objectSpread(_objectSpread({}, existingEntry), newObject);
        } else {
          return newObject;
        }
      }

      return newObject;
    }; // Method to save the actual item


    var storeToDB = function storeToDB(dataFromDB) {
      var dataObjectToStore = mergeDatabaseDataWithLocalChanges(dataFromDB, keyName, keyValue);

      if (_this.ldapEnabled) {
        _this.setLocalItem(keyName, dataObjectToStore, callback);
      } else {
        // url PUT /_api/user/<username>/config/<key>
        var self = _this;
        $.ajax({
          type: 'PUT',
          cache: false,
          url: arangoHelper.databaseUrl('/_api/user/' + encodeURIComponent(_this.username) + '/config/' + encodeURIComponent(keyName)),
          contentType: 'application/json',
          processData: false,
          data: JSON.stringify({
            value: dataObjectToStore
          }),
          async: true,
          success: function success() {
            self.set(keyName, dataObjectToStore);

            if (callback) {
              callback(dataObjectToStore);
            }
          },
          error: function error() {
            arangoHelper.arangoError('User configuration', 'Could not update user configuration for key: ' + keyName);
          }
        });
      }
    };
  },
  getItem: function getItem(keyName, callback) {
    if (this.ldapEnabled) {
      this.getLocalItem(keyName, callback);
    } else {
      // url GET /_api/user/<username>/config/<key>
      $.ajax({
        type: 'GET',
        cache: false,
        url: arangoHelper.databaseUrl('/_api/user/' + encodeURIComponent(this.username) + '/config/' + encodeURIComponent(keyName)),
        contentType: 'application/json',
        processData: false,
        async: true,
        success: function success(keyValue) {
          callback(keyValue);
        },
        error: function error() {
          arangoHelper.arangoError('User configuration', 'Could not fetch user configuration for key: ' + keyName);
        }
      });
    }
  }
});